<script setup lang="ts">
import { useDisplay } from 'vuetify'

const { smAndUp } = useDisplay()
</script>

<template>
  <div class="px-4 py-0 mx-0 my-0 bg-accent">
    <v-container fluid class="pa-0 ma-0">
      <div class="maxWidth">
        <v-row class="py-16" align="center">
          <v-col cols="12" md="2" sm="3" :align="smAndUp ? 'start' : 'center'">
            <div class="rounded-circle" style="height: 150px; width: 150px">
              <v-img class="rounded-circle" src="/images/webp/Felix_Nithammer_web.webp" />
            </div>
          </v-col>
          <v-col cols="12" md="10" sm="9" :align="smAndUp ? 'start' : 'center'">
            <p class="text-h4">Ihr Ansprechpartner</p>
            <p class="text-h1 pb-4">Felix Nithammer</p>
            <p class="text-h5 font-weight-bold">
              <v-icon size="22" class="mr-2">mdi-phone</v-icon>
              <NuxtLink class="link mr-4" href="tel:+4921225087380"> +49 212 25087380 </NuxtLink>
              <v-icon size="22" class="mr-1">mdi-email-outline</v-icon>
              <NuxtLink class="link" href="mailto:f.nithammer@dreissigelf.de">
                f.nithammer@dreissigelf.de
              </NuxtLink>
            </p>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </div>
</template>

<style scoped lang="scss">
.link {
  color: rgb(var(--v-theme-black));
  text-decoration: none;

  &:hover {
    text-decoration: underline;
    text-decoration-color: rgb(var(--v-theme-primary));
    text-decoration-thickness: 2px;
    text-underline-offset: 3px;
  }
}
</style>
